import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  title?: string
}

export const MapButton = memo(function MapButton({ cta, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <IconLogo />

      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}
      {cta ? <Button variant="outline" {...cta} /> : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin: 9.375rem 0 6.25rem;
  padding: 5.0625rem 0 6.125rem;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0 5rem;
    padding: 3.75rem 0 4.375rem;

    > svg {
      width: 3.75rem;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  line-height: 4rem;
  margin: 0 auto 3.75rem;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`
