import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'

import { Props as ReviewProps, Review } from './Review'

const AUTOPLAY_TIMER = 5000

export interface Props {
  languageCode: string
  reviews: ReviewProps[]
}

export const CustomerReviews = memo(function CustomerReviews({
  languageCode,
  reviews,
}: Props) {
  if (!reviews) {
    return null
  }

  if (reviews.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
    },
    [KeyboardControls],
  )

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef && reviews.length > 1) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <IconLogo />

      <Label>{useVocabularyData('about-us', languageCode)}</Label>
      <Slider ref={sliderRef}>
        {uniq(reviews).map((item, index) => (
          <Review className="keen-slider__slide" key={index} {...item} />
        ))}
      </Slider>

      {reviews.length > 1 ? (
        <Arrows dial={5} row>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: url('/icon-logo.png') no-repeat 10.556vw 7.875rem;
  margin-top: 12.5rem;
  padding: 7.9375rem 0 12.5rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 50%;
    height: 100vh;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: 22.625rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 50%;
    right: 10.556vw;
    transform: translateY(-50%);
    z-index: -1;
  }

  @media (max-width: 1199px) {
    background-position-x: 1.875rem;
    margin-top: 7.5rem;
    padding: 6.25rem 0 7.5rem;

    > svg {
      width: 18.75rem;
      right: 1.875rem;
    }
  }

  @media (max-width: 1023px) {
    background: none;
    margin: 5.625rem 0 9.375rem;
    padding: 0 1.875rem;
    &:before {
      display: none;
    }
    > svg {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Slider = styled.div`
  display: flex;
  max-width: 35rem;
  outline: none;
  overflow: hidden;
  margin: 1.875rem auto 0;
  position: relative;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const Arrows = styled(FlexBox)`
  margin: 3rem auto 0;
  > div {
    margin: 0 1.875rem;
  }

  @media (max-width: 767px) {
    > div {
      margin: 0 1.25rem;
    }
  }
`
