const KeyboardControls = (s: any) => {
  let focused = false

  function eventFocus() {
    focused = true
  }

  function eventBlur() {
    focused = false
  }

  function eventKeydown(e: any) {
    if (!focused) return
    switch (e.key) {
      default:
        break
      case 'Left':
      case 'ArrowLeft':
        s.prev()
        break
      case 'Right':
      case 'ArrowRight':
        s.next()
        break
    }
  }

  s.on('created', () => {
    s.container.setAttribute('tabindex', 0)
    s.container.addEventListener('focus', eventFocus)
    s.container.addEventListener('blur', eventBlur)
    s.container.addEventListener('keydown', eventKeydown)
  })
}

export default KeyboardControls
