import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  description?: string
  source?: string
  user: string
}

export const Review = memo(function Review({
  className,
  description,
  source,
  user,
}: Props) {
  if (!user) {
    return null
  }

  return (
    <Container className={className}>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <Info dial={2} row>
        <User>{user}</User>
        {source ? <Source>{source}</Source> : null}
      </Info>
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
`

const Info = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const User = styled.div``

const Source = styled.div`
  &:before {
    content: ', ';
  }
`
